const awardModule = {
  state: () => ({
    awardSelectedData: null,
    companyName: null,
    pin: null,
    code: null,
    id: null,
    awardedByFirstName: null,
    awardedByLastName: null,
    awardedBy: null,
    familyAwardId: null,
    memberData: [],
    dqa: {},
    vqa: {},
    questionsForAwardDetermination: [],
    questionsForAwardValue: [],
    preSelectedAwardIds: null,
    emitScrollToTop: true,
    allowAutoGrow: true,
    preSelectedAward: null,
    preSelectedAwardFamily: null,
    continueSessionIntervalInSecs: null,
    language: null
  }),
  mutations: {
    set_data (state, data) {
      state.companyName = data.companyName
      state.pin = data.authenticate.pin
      state.code = data.hierarchyNodeCode
      state.id = data.session.access.id
      state.awardedByFirstName = data.firstName
      state.awardedByLastName = data.lastName
      state.language = data.language
    },
    set_award_details (state, data) {
      state.awardSelectedData = data
    },
    set_family_award_id (state, data) {
      state.familyAwardId = data
    },
    set_members_data (state, data) {
      state.memberData = data
    },
    set_dqa_data (state, data) {
      state.dqa = data
    },
    set_vqa_data (state, data) {
      state.vqa = data
    },
    set_details_for_determination (state, data) {
      state.questionsForAwardDetermination = data
    },
    set_details_for_value (state, data) {
      state.questionsForAwardValue = data
    },
    set_pre_selected_award_ids (state, data) {
      state.preSelectedAwardIds = data
    },
    set_scroll_to_top (state, data) {
      state.emitScrollToTop = data
    },
    set_allow_auto_grow (state, data) {
      state.allowAutoGrow = data
    },
    set_preselected_award_details (state, data) {
      state.preSelectedAward = data
    },
    set_preselected_award_family_details (state, data) {
      state.preSelectedAwardFamily = data
    },
    set_continue_session_interval_freq (state, data) {
      state.continueSessionIntervalInSecs = data
    }
  },
  getters: {
    companyName: state => state.companyName,
    pin: state => state.pin,
    code: state => state.code,
    id: state => state.id,
    awardSelectedData: state => state.awardSelectedData,
    awardedByFirstName: state => state.awardedByFirstName,
    awardedByLastName: state => state.awardedByLastName,
    awardedBy: state => state.awardedBy,
    familyAwardId: state => state.familyAwardId,
    pinOfMembers: state => state.memberData.map(val => {
      if (val) {
        return val.pin
      }
    }),
    dqa: state => state.dqa === null || state.dqa === undefined ? {} : state.dqa,
    vqa: state => state.vqa === null || state.vqa === undefined ? {} : state.vqa,
    awardDeterminationQuestions: state => state.questionsForAwardDetermination,
    questionsForAwardValue: state => state.questionsForAwardValue,
    preSelectedAwardIds: state => state.preSelectedAwardIds,
    emitScrollToTop: state => state.emitScrollToTop,
    allowAutoGrow: state => state.allowAutoGrow,
    preSelectedAward: state => state.preSelectedAward,
    preSelectedAwardFamily: state => state.preSelectedAwardFamily,
    continueSessionIntervalInSecs: state => state.continueSessionIntervalInSecs,
    language: state => state.language
  },
  actions: {
    setUserData ({commit}, data) {
      commit('set_data', data)
    },
    selectedAwards ({commit}, data) {
      commit('set_award_details', data)
    },
    updateFamilyAward ({commit}, data) {
      commit('set_family_award_id', data)
    },
    updateSelectedMembers ({commit}, data) {
      commit('set_members_data', data)
    },
    updateDQA ({commit}, data) {
      commit('set_dqa_data', data)
    },
    updateVQA ({commit}, data) {
      commit('set_vqa_data', data)
    },
    setAwardDeterminationQuestions ({commit}, data) {
      commit('set_details_for_determination', data)
    },
    setAwardValueQuestions ({commit}, data) {
      commit('set_details_for_value', data)
    },
    resetAwardData ({dispatch}, data) {
      dispatch('updateDQA', {})
      dispatch('updateVQA', {})
      dispatch('updateSelectedMembers', [])
      dispatch('selectedAwards', null)
    },
    setPreselectedAwardIds ({commit}, data) {
      commit('set_pre_selected_award_ids', data)
    },
    setScrollToTop ({commit}, data) {
      commit('set_scroll_to_top', data)
    },
    setAllowAutoGrow ({commit}, data) {
      commit('set_allow_auto_grow', data)
    },
    setPreselectedAward ({commit}, data) {
      commit('set_preselected_award_details', data)
    },
    setPreselectedAwardFamily ({commit}, data) {
      commit('set_preselected_award_family_details', data)
    },
    setContinueSessionInterval ({ commit }, data) {
      commit('set_continue_session_interval_freq', data)
    }
  }
}

export default awardModule;