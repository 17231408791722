import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueI18n from 'vue-i18n';
import en from '@/locale/english.json';
import fr from '@/locale/french.json';
import '@fortawesome/fontawesome-pro/css/all.min.css'


// One of the options
Vue.use(VueToast, {
});
// for langauge
Vue.use(VueI18n);
const messages = {
  en: { message: en },
  fr: { message: fr }
};

const i18n = new VueI18n({
  locale: "en",
  messages
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
