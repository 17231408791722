import Vue from "vue";
import Vuex from "vuex";
import awardModule from './award.js'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    iframeId: null,
    domain: null,
    translations: {}
  },
  mutations: {
    set_iframe_id (state, data) {
      state.iframeId = data
    },
    set_domain (state, data) {
      state.domain = data
    },
    set_translations (state, data) {
      state.translations = data
    }
  },
  getters: {
    parentIframeId: state => state.iframeId,
    domain: state => state.domain,
    translations: state => state.translations
  },
  actions: {
    setIframeId ({commit}, data) {
      commit('set_iframe_id', data)
    },
    setDomain ({commit}, data) {
      commit('set_domain', data)
    },
    setTranslations ({commit}, data) {
      commit('set_translations', data)
    }
  },
  modules: {
    awardModule
  }
});
