import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Validate",
    component: () => import("@/view/validate.vue")
  },
  {
    path: "/award",
    name: "AwardStepper",
    component: () => import("@/view/awardStepper/stepper.vue")
  },
  {
    path: "/videos",
    name: "Videos",
    component: () => import("@/view/validate.vue")
  },
  {
    path: "/nomination/videos",
    name: "VideoPlayer",
    component: () => import('@/view/videoDashboard/videolist.vue')
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import('@/components/401.vue')
  },
  {
    path: "/permission",
    name: "CannotNominate",
    component: () => import('@/components/cannotNominate.vue')
  },
  {
    path: "/serverError",
    name: "ServerError",
    component: () => import('@/components/500.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
