<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import sendResize from '@/mixin/index.js';

export default {
  name: "app",
  data () {
    return {
    }
  },
  mixins: [sendResize],
  mounted () {
    const self = this;
    window.addEventListener("message", function(event) {
      if (event.data.event) {
        if (event.data.event === "p2mResize") {
            self.sendResizeContent(false, false);
        } else if (event.data.event === 'initialize') {
            self.$store.dispatch('setIframeId', event.data.iFrameId)
            self.sendResizeContent(true, true);
        }
      }
    }, false);

    window.addEventListener("resize", function() {
      // On resize preventing scroll to top 
        self.sendResizeContent(false, false);
    });
  }
}
</script>
<style>
@import './assets/styles/selectdropdown.css';
@import './assets/styles/style.css';
</style>