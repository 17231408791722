export default {
  methods: {
    sendResizeContent (isInitialized, forceScrollToTop) {
      let iframeId = this.$store.getters.parentIframeId
      let allowAutoGrow = this.$store.getters.allowAutoGrow
      if (isInitialized === undefined) {
          isInitialized = false;
      }
      if (iframeId === null || !allowAutoGrow) {
          return false;
      }
      if (forceScrollToTop) {
        if (!this.$store.getters.emitScrollToTop) {
          forceScrollToTop = false
        }
      } else {
        forceScrollToTop = false
      }
      if (iframeId) isInitialized = true

      const payload = {
          event: 'resizeContent',
          params: {
              height: document.body.scrollHeight + 'px',
              width: document.body.scrollWidth + 'px',
              iFrameId: iframeId,
              scrollToTop: forceScrollToTop,
              isInitialized: isInitialized
          }
      };
      window.parent.postMessage(payload, '*');
    },
    requestInitialPostMessage () {
      const payload = {
        event: 'initPoll'
      }
      window.parent.postMessage(payload, '*')
    }
  }
}